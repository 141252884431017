import React, { useState } from 'react';
import DebouncedInput from '../DebouncedInput';
import { Col, Form, Modal, Row, Select, message } from 'antd';
import api from '../../service';

const SetError = ({ id }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [data, setData] = useState({
    errorCode: 'GroundFailure',
  });

  const update = async () => {
    setConfirmLoading(true);

    await api
      .post(`/rest/simulator/v1/setError`, data)
      .then(({ data }) => {
        if (data && data.result) {
          message.success('Simulator başarıyla oluşturuldu ...');
        } else {
          message.error('Simulator Oluşturulamadı');
        }
      })
      .catch((err) => {
        console.log(err);
        message.error('Simulator Oluşturulamadı !!!');
      });

    setTimeout(() => {
      setIsModalOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };

  return (
    <div>
      <style>
        {`
          .ant-modal-footer > button:first-child {
              display: inline-block !important;
          }
        `}
      </style>
      <button
        type="button"
        className="btn btn-danger me-3 btn-lg"
        size={'large'}
        onClick={() => setIsModalOpen(true)}
      >
        <i className="fa-solid fa-charging-station"></i>
        Error Set
      </button>
      <Modal
        title="Error Set"
        open={isModalOpen}
        okText="Başlat"
        cancelText="Vazgeç"
        confirmLoading={confirmLoading}
        onOk={() => update()}
        onCancel={() => setIsModalOpen(false)}
      >
        <Form
          layout="horizontal"
          size={'large'}
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 22,
          }}
          onFinish={update}
        >
          <Row direction="row">
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item
                label="Cihaz Id"
                name="deviceId"
                rules={[
                  {
                    required: true,
                    message: 'Lütfen cihaz id girin !',
                  },
                ]}
              >
                <DebouncedInput
                  delay={150}
                  name="deviceId"
                  record={data}
                  type="text"
                  setRecord={setData}
                />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item
                label="Konnektör Id"
                name="connectorId"
                rules={[
                  {
                    required: true,
                    message: 'Lütfen connector id girin !',
                  },
                ]}
              >
                <DebouncedInput
                  delay={150}
                  name="connectorId"
                  record={data}
                  type="text"
                  setRecord={setData}
                />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item label="Hata Türü" name="errorCode">
                <Select
                  defaultValue="GroundFailure"
                  style={{
                    width: 120,
                  }}
                  onChange={(v) => setData({ ...data, errorCode: v })}
                  options={[
                    {
                      value: 'GroundFailure',
                      label: 'GroundFailure',
                    },
                    {
                      value: 'Finishing',
                      label: 'Finishing',
                    },
                    {
                      value: 'Available',
                      label: 'Available',
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default SetError;
